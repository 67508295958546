<template>
  <div>
    <el-tabs v-model="currentTabName">
      <el-tab-pane
        label="基本資料"
        name="basic"
        v-if="isShowBasicTab">
        <div
          class="company-container"
          v-loading="isCompanyDataLoading">
          <section
            v-if="isShowDescription&&companyData.basic">
            <!-- <h4 class="section-title">公司簡介</h4>
            <pre style="white-space:pre-wrap">{{companyDescription}}</pre> -->
            <CompanyDescriptionSection
              :description="companyDescription">
            </CompanyDescriptionSection>
          </section>
          <section
            v-if="isShowBasic&&companyData.basic">
            <CompanyBasicTable
              :basic="companyData.basic">
            </CompanyBasicTable>
          </section>
          <section
            v-if="isShowShareholders&&companyData.shareholders">
            <ShareholdersTable
              :shareholders="companyData.shareholders"
              :uniID="currentUniID"
              :stockType="stockType">
            </ShareholdersTable>
          </section>
          <section
            v-if="isShowManagers&&companyData.managers">
            <ManagersTable
              :managers="companyData.managers"
              :uniID="currentUniID">
            </ManagersTable>
          </section>
          <section
            v-if="isShowCompanysHistory&&companyData.companys_history">
            <CompanysHistoryTable
              :companys_history="companyData.companys_history"
              :uniID="currentUniID">
            </CompanysHistoryTable>
          </section>
          <section
            v-if="isShowCompanyBranchs&&companyData.company_branchs">
            <CompanyBranchsTable
              :company_branchs="companyData.company_branchs"
              :uniID="currentUniID">
            </CompanyBranchsTable>
          </section>
          <section
            v-if="isShowFactorys&&companyData.factorys">
            <FactorysTable
              :factorys="companyData.factorys"
              :uniID="currentUniID">
            </FactorysTable>
          </section>
          <section
            v-if="isShowSimilarAddress&&companyData.登記地址近似公司">
            <SimilarAddressTable
              :登記地址近似公司="companyData.登記地址近似公司"
              :uniID="currentUniID">
            </SimilarAddressTable>
          </section>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="新聞事件"
        name="news"
        v-if="isShowNewsTab">
        <div
          class="company-container"
          v-loading="isCompanyDataLoading">
          <section
            v-if="isShowNews&&companyNews">
            <NewsList
              :news="companyNews"
              :uniID="currentUniID"
              :companyName="companyName">
            </NewsList>
          </section>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="法律訴訟"
        name="verdicts"
        v-if="isShowVerdictsTab">
        <div
          class="company-container"
          v-loading="isCompanyDataLoading">
          <section
            v-if="isShowVerdicts&&companyData.verdicts">
            <VerdictsList
              :verdicts="companyData.verdicts"
              :basic="companyData.basic"
              :uniID="currentUniID">
            </VerdictsList>
          </section>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="關係人地圖"
        name="stock"
        v-if="isShowStockTab">
        <div
          class="company-container"
          v-if="currentTabName=='stock'"
          v-loading="isCompanyDataLoading">
          <section
            v-if="isShowStockRelationshipChart">
            <h4 class="section-title">
              股權結構分析圖
              <el-button
                type="primary"
                size="mini"
                style="margin-left:10px"
                v-if="chartVersion==1"
                @click="handleChartVersionChange">
                體驗新版
              </el-button>
              <el-button
                plain
                size="mini"
                style="margin-left:10px"
                v-else-if="chartVersion==2"
                @click="handleChartVersionChange">
                恢復舊版
              </el-button>
            </h4>
            <!-- <CompanyStockRelationshipChart
              v-loading="isStockLoading"
              :uniID="currentUniID"
              :companyDataList="companyStockList"
              :companyName="companyName">
            </CompanyStockRelationshipChart> -->
            <CompanyStockRelationshipChart
              v-if="chartVersion==1"
              v-loading="isGoFullLoading"
              :uniID="currentUniID"
              :sourceData="companyGoFull"
              :companyName="companyName">
            </CompanyStockRelationshipChart>
            <CompanyStockTree2Chart
              v-else-if="chartVersion==2"
              v-loading="isGoFullLoading"
              :uniID="currentUniID"
              :chartData="companyGoFull"
              :companyName="companyName">
            </CompanyStockTree2Chart>
          </section>
          <section
            v-if="isShowStockDiractedChart">
            <h4 class="section-title">
              企業關聯圖譜
            </h4>
            <!-- <CompanyStockDirectedChart
              v-loading="isStockLoading"
              :uniID="currentUniID"
              :companyDataList="companyStockList"
              :companyName="companyName">
            </CompanyStockDirectedChart> -->
            <CompanyStockDirectedChart
              v-loading="isGoOldLoading"
              :uniID="currentUniID"
              :sourceData="companyGoOld"
              :companyName="companyName">
            </CompanyStockDirectedChart>
          </section>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="風險指標"
        name="risk"
        v-if="isShowRiskTab">
        <div
          class="company-container"
          v-loading="isCompanyDataLoading">
          <!-- <section
            v-if="isShowVerdicts&&companyData.verdicts">
            <VerdictsTable
              :verdicts="companyData.verdicts"
              :basic="companyData.basic"
              :uniID="currentUniID">
            </VerdictsTable>
          </section> -->
          <section
            v-if="isShowDisputes&&companyData.disputes">
            <DisputesTable
              :disputes="companyData.disputes"
              :uniID="currentUniID">
            </DisputesTable>
          </section>
          <section
            v-if="isShowProcurementBadVendors&&companyData.procurement_bad_vendors">
            <ProcurementBadVendorsTable
              :procurement_bad_vendors="companyData.procurement_bad_vendors"
              :uniID="currentUniID">
            </ProcurementBadVendorsTable>
          </section>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="經營指標"
        name="operating"
        v-if="isShowOperatingTab">
        <div
          class="company-container"
          v-loading="isCompanyDataLoading">
          <section
            v-if="isShowTrades&&companyData.trades">
            <TradesTable
              :basic="companyData.basic"
              :trades="companyData.trades"
              :uniID="currentUniID">
            </TradesTable>
          </section>
          <section
            v-if="isShowProcurements&&companyData.procurements">
            <ProcurementsTable
              :basic="companyData.basic"
              :procurements="companyData.procurements"
              :uniID="currentUniID">
            </ProcurementsTable>
          </section>
          <section
            v-if="isShowJobs&&companyData.job104s_jobs">
            <JobsTable
              :basic="companyData.basic"
              :job104s_jobs="companyData.job104s_jobs"
              :uniID="currentUniID">
            </JobsTable>
          </section>
          <section
            v-if="isShowPatents&&companyData.patents">
            <PatentsTable
              :patents="companyData.patents"
              :uniID="currentUniID">
            </PatentsTable>
          </section>
          <section
            v-if="isShowAwards&&companyData.awards">
            <AwardsTable
              :awards="companyData.awards"
              :uniID="currentUniID">
            </AwardsTable>
          </section>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="財務指標"
        name="finance"
        v-if="isShowFinanceTab">
        <div
          class="company-container"
          v-loading="isCompanyDataLoading">
          <section
            v-if="isShowPublicsNews&&companyData.publics_news">
            <PublicsNewsTable
              :basic="companyData.basic"
              :publics_news="companyData.publics_news"
              :uniID="currentUniID">
            </PublicsNewsTable>
          </section>
          <section
            v-if="isShowRevenueHistory&&companyData.revenue_history">
            <RevenueHistoryTable
              :basic="companyData.basic"
              :revenue_history="companyData.revenue_history"
              :uniID="currentUniID">
            </RevenueHistoryTable>
          </section>
          <section
            v-if="isShowIfrssSummary&&companyData.ifrss_summary">
            <IfrssSummaryTable
              :ifrss_summary="companyData.ifrss_summary"
              :uniID="currentUniID">
            </IfrssSummaryTable>
          </section>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import * as apis from '@/apis/index.ts'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import getCompanyStockList from '@/utils/getCompanyStockList_legacy.js'
import CompanyDescriptionSection from '@/components/CompanyDescriptionSection/CompanyDescriptionSection.vue'
import CompanyBasicTable from '@/components/CompanyBasicTable/CompanyBasicTable.vue'
import ShareholdersTable from '@/components/ShareholdersTable/ShareholdersTable.vue'
import ManagersTable from '@/components/ManagersTable/ManagersTable.vue'
import CompanysHistoryTable from '@/components/CompanysHistoryTable/CompanysHistoryTable.vue'
import CompanyBranchsTable from '@/components/CompanyBranchsTable/CompanyBranchsTable.vue'
import FactorysTable from '@/components/FactorysTable/FactorysTable.vue'
import SimilarAddressTable from '@/components/SimilarAddressTable/SimilarAddressTable.vue'
// import VerdictsTable from '@/components/VerdictsTable/VerdictsTable.vue'
import NewsList from '@/components/NewsList/NewsList.vue'
import VerdictsList from '@/components/VerdictsList/VerdictsList.vue'
// import CompanyStockRelationshipChart from '@/components/CompanyStockRelationshipChart_legacy/CompanyStockRelationshipChart.vue'
// import CompanyStockDirectedChart from '@/components/CompanyStockDirectedChart_legacy/CompanyStockDirectedChart.vue'
import CompanyStockRelationshipChart from '@/components/charts/businessNetwork/CompanyStockRelationshipChart.vue'
import CompanyStockTree2Chart from '@/components/charts/businessNetwork/CompanyStockTree2Chart.vue'
import CompanyStockDirectedChart from '@/components/charts/businessNetwork/CompanyStockDirectedChart.vue'
// import NewsTable from '@/components/NewsTable/NewsTable.vue'
import DisputesTable from '@/components/DisputesTable/DisputesTable.vue'
import ProcurementBadVendorsTable from '@/components/ProcurementBadVendorsTable/ProcurementBadVendorsTable.vue'
import TradesTable from '@/components/TradesTable/TradesTable.vue'
import ProcurementsTable from '@/components/ProcurementsTable/ProcurementsTable.vue'
import JobsTable from '@/components/JobsTable/JobsTable.vue'
import PatentsTable from '@/components/PatentsTable/PatentsTable.vue'
import AwardsTable from '@/components/AwardsTable/AwardsTable.vue'
import PublicsNewsTable from '@/components/PublicsNewsTable/PublicsNewsTable.vue'
import RevenueHistoryTable from '@/components/RevenueHistoryTable/RevenueHistoryTable.vue'
import IfrssSummaryTable from '@/components/IfrssSummaryTable/IfrssSummaryTable.vue'
import toOldFormatV4 from '@/utils/to_old_format_v4.js'
import toOldFormat from '@/utils/to_old_format.js'
import relationshipChartVersionStorage from '@/storage/relationshipChartVersionStorage'

export default {
  props: {
    'menuIndex': {}
  },
  components: {
    CompanyDescriptionSection,
    CompanyBasicTable,
    ShareholdersTable,
    ManagersTable,
    CompanysHistoryTable,
    CompanyBranchsTable,
    FactorysTable,
    SimilarAddressTable,
    // VerdictsTable,
    NewsList,
    VerdictsList,
    CompanyStockRelationshipChart,
    CompanyStockTree2Chart,
    CompanyStockDirectedChart,
    // NewsTable,
    DisputesTable,
    ProcurementBadVendorsTable,
    TradesTable,
    ProcurementsTable,
    JobsTable,
    PatentsTable,
    AwardsTable,
    PublicsNewsTable,
    RevenueHistoryTable,
    IfrssSummaryTable
  },
  data () {
    return {
      currentTabName: '',
      // isCompanyDataLoading: true
      isStockLoading: false,
      isGoFullLoading: false,
      isGoOldLoading: false,
      // companyGoFull: {
      //   nodes: [],
      //   edges: []
      // },
      // companyGoOld: {
      //   nodes: [],
      //   edges: []
      // },
      companyStockList: [], // 關係人地圖資料（圖表上所有節點的公司股權資料）
      chartVersion: 1
    }
  },
  computed: {
    ...mapState('multiCompanyDetail', [
      'getList',
      'multiCompanyData',
      'multiCompanyNews',
      'multiGoFull',
      'multiGoOld',
      'isCompanyDataLoading',
      'isCompanyNewsLoading',
      'isShowDescription',
      'isShowBasic',
      'isShowShareholders',
      'isShowManagers',
      'isShowCompanysHistory',
      'isShowCompanyBranchs',
      'isShowFactorys',
      'isShowSimilarAddress',
      'isShowVerdicts',
      'isShowNews',
      'isShowStockRelationshipChart',
      'isShowStockDiractedChart',
      'isShowDisputes',
      'isShowProcurementBadVendors',
      'isShowTrades',
      'isShowProcurements',
      'isShowJobs',
      'isShowPatents',
      'isShowAwards',
      'isShowPublicsNews',
      'isShowRevenueHistory',
      'isShowIfrssSummary'
    ]),
    ...mapGetters('multiCompanyDetail', [
      'getterMultiStockType'
    ]),
    // 目前的uniID
    currentUniID () {
      return this.menuIndex
    },
    companyName () {
      if (this.companyData && this.companyData.basic && this.companyData.basic.company_name) {
        return this.companyData.basic.company_name
      } else {
        return ''
      }
    },
    // 目前的公司對應公司陣列索引
    currentCompanyIndex () {
      if (this.multiCompanyData.length && this.menuIndex) {
        const uniIDArr = this.multiCompanyData.map(d => String(d.basic.uniID))
        return uniIDArr.indexOf(String(this.menuIndex))
      } else {
        return ''
      }
    },
    companyData () {
      if (this.multiCompanyData.length && this.currentCompanyIndex >= 0) {
        return this.multiCompanyData[this.currentCompanyIndex]
      } else {
        return {}
      }
    },
    companyNews () {
      if (this.multiCompanyNews.length && this.currentCompanyIndex >= 0) {
        return this.multiCompanyNews[this.currentCompanyIndex]
      } else {
        return null
      }
    },
    stockType () {
      if (this.getterMultiStockType.length && this.currentCompanyIndex >= 0) {
        return this.getterMultiStockType[this.currentCompanyIndex]
      } else {
        return ''
      }
    },
    isShowBasicTab () {
      if (this.isShowDescription || this.isShowBasic || this.isShowShareholders) {
        return true
      } else {
        return false
      }
    },
    isShowNewsTab () {
      if (this.isShowNews) {
        return true
      } else {
        return false
      }
    },
    isShowVerdictsTab () {
      if (this.isShowVerdicts) {
        return true
      } else {
        return false
      }
    },
    isShowStockTab () {
      if (this.isShowStockRelationshipChart || this.isShowStockDiractedChart) {
        return true
      } else {
        return false
      }
    },
    isShowRiskTab () {
      if (this.isShowVerdicts || this.isShowDisputes || this.isShowProcurementBadVendors) {
        return true
      } else {
        return false
      }
    },
    isShowOperatingTab () {
      if (this.isShowTrades || this.isShowProcurements || this.isShowPatents || this.isShowAwards) {
        return true
      } else {
        return false
      }
    },
    isShowFinanceTab () {
      if (this.isShowPublicsNews || this.isShowRevenueHistory || this.isShowIfrssSummary) {
        return true
      } else {
        return false
      }
    },
    companyDescription () {
      if (this.companyData && this.companyData.basic && this.companyData.basic.multi && this.companyData.basic.multi.description && this.companyData.basic.multi.description.length) {
        return this.companyData.basic.multi.description[0].value || '-'
      } else {
        return '-'
      }
    },
    companyGoFull () {
      return this.multiGoFull[this.currentUniID]
    },
    companyGoOld () {
      return this.multiGoOld[this.currentUniID]
    }
  },
  created () {
    // 預設active tab
    if (this.isShowBasicTab) {
      this.currentTabName = 'basic'
    } else if (this.isShowRiskTab) {
      this.currentTabName = 'risk'
    } else if (this.isShowOperatingTab) {
      this.currentTabName = 'operating'
    } else if (this.isShowFinanceTab) {
      this.currentTabName = 'finance'
    }
  },
  watch: {
    'currentTabName': {
      async handler (d) {
        // 切換到「關係人地圖」頁籤，並且資料未取得

        // -- @Q@ 舊版 --
        // if (d === 'stock' && this.companyStockList.length === 0) {
        //   this.isStockLoading = true
        //   try {
        //     this.companyStockList = await getCompanyStockList(this.currentUniID)
        //   } catch (e) {
        //     console.log(e)
        //   }
        //   this.isStockLoading = false
        // }

        if (d === 'stock' && !this.multiGoFull[this.currentUniID]) {
          // this.actionMultiCompanyGraphForDbs(this.currentUniID)
          this.isGoFullLoading = true
          this.isGoOldLoading = true

          const fullPromise = apis.graphForDbs({
            uniid: this.currentUniID,
            skip_gov_neibor: '1',
            skip_independent_director_neibor: '1',
            only_in_bussiness: false
          })
          .then(data => {
            this.isGoFullLoading = false

            const parsedData = toOldFormatV4(data)
            console.log('toOldFormatV4 - full', parsedData)
            this.mutationMultiGoFull({
              uniID: this.currentUniID,
              payload: {
                nodes: parsedData.d3.nodes,
                edges: parsedData.d3.edges
              }
            })
            // this.companyGoFull = {
            //   nodes: parsedData.d3.nodes,
            //   edges: parsedData.d3.edges
            // }
            return true
          })
          .catch(d => {
            this.isGoFullLoading = false
            return Promise.reject(d)
          })
          const upPromise = apis.graphForDbsOld({
            uniid: this.currentUniID,
            "mode": "up",
            "format": "json",
            "mini": "0",
            "scope": "classic",
            "force_split": "1",
            only_in_bussiness: false
          })
          .then(data => {
            this.isGoOldLoading = false

            const parsedData = toOldFormat(data)
            console.log('toOldFormat - up', parsedData)
            this.mutationMultiGoOld({
              uniID: this.currentUniID,
              payload: {
                nodes: parsedData.d3.nodes,
                edges: parsedData.d3.edges
              }
            })
            // this.companyGoOld = {
            //   nodes: parsedData.d3.nodes,
            //   edges: parsedData.d3.edges
            // }
            return true
          })
          .catch(d => {
            this.isGoOldLoading = false
            return Promise.reject(d)
          })

          // Promise.all([fullPromise, upPromise])
          //   .then(d => {

          //   })
          //   .catch(e => {

          //   })
        }
      },
    },
    'menuIndex': {
      handler (d) {
        this.companyStockList = []
        // 切換公司回到預設頁籤
        this.currentTabName = 'basic'
      }
    }
  },
  methods: {
    // ...mapActions('multiCompanyDetail', [
    //   'actionMultiCompanyGraphForDbs'
    // ])
    ...mapMutations('multiCompanyDetail', [
      'mutationMultiGoFull',
      'mutationMultiGoOld'
    ]),
    handleChartVersionChange () {
      if (this.chartVersion == 1) {
        this.chartVersion = 2
      } else {
        this.chartVersion = 1
      }
      relationshipChartVersionStorage.setVersion(this.chartVersion)
    },
  }
}
</script>
